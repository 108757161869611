import React from 'react'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'

const Page = () => (
  <Content
    heading="BubbleList"
    subHeading="Verkkopalvelu"
  >
    <Playground format="html">
      <ol className="bubble-list mb-0">
        <li className="bubble-list-item">
          <span className="bubble-list-item-bullet" />
          <span>
            <h3 className="h4 mb-0">Luo profiili</h3>
            <p className="mb-0">Kerro osaamisestasi ja työkokemuksestasi</p>
          </span>
        </li>
        <li className="bubble-list-item">
          <span className="bubble-list-item-bullet" />
          <span>
            <h3 className="h4 mb-0">Julkaise profiili</h3>
            <p className="mb-0">
              Kerro osaamisestasi ja työkokemuksestasi - päätät itse mitkä
              tiedot näkyvät työnantajalle
            </p>
          </span>
        </li>
        <li className="bubble-list-item">
          <span className="bubble-list-item-bullet" />
          <span>
            <h3 className="h4 mb-0">Työpaikkasuositukset</h3>
            <p className="mb-0">Katso sinulle sopivimmmat avoimet työpaikat</p>
          </span>
        </li>
      </ol>
    </Playground>
  </Content>
)

export default Page
